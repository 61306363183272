import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const successMessage = "Viesti lähetetty!";
const minorErrorMessage = "Jotakin meni pieleen. Ethän ole botti?";
const majorErrorMessage =
  "Eipä onnistunut. Kokeile sähköpostilla, täällä on joku säätö menossa nyt!";

const ContactForm = () => {
  const meta = useSiteMetadata();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [newsletter, setNewsletter] = useState(false);

  const [noSubmit, setNoSubmit] = useState(false);

  const onContactFormSubmit = e => {
    e.preventDefault();

    if (email != null && message != null && captcha === "4" && !noSubmit) {
      setNoSubmit(true);

      setTimeout(() => {
        setNoSubmit(false);
      }, 5000);

      fetch(meta.conUrl, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sender: email,
          message,
          captcha,
          newsletter,
          hidden: meta.hid,
        }),
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          if (data && data.message && data.message === "OK") {
            setEmail("");
            setMessage("");
            setCaptcha("");
            setNewsletter(false);
            toast.success(successMessage, { toastId: "hyväleipä" });
          } else {
            toast.error(minorErrorMessage, {
              toastId: "pahaleipä",
            });
          }
        })
        .catch(err => {
          toast.error(majorErrorMessage, {
            toastId: "pahempileipä",
          });
        });
    } else {
      toast.error(minorErrorMessage, {
        toastId: "pahaleipä",
      });
    }
  };

  return (
    <Form className="pe-lg-5 pb-5" onSubmit={onContactFormSubmit}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Form.Group controlId="contactForm.email" className="pb-3">
        <Form.Label style={{ fontSize: "18px" }}>
          Sähköpostiosoitteesi
        </Form.Label>
        <Form.Control
          className="bg-transparent text-foreground"
          type="email"
          value={email}
          placeholder="email@example.com"
          required
          onChange={e => setEmail(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="contactForm.message" className="pb-4">
        <Form.Label style={{ fontSize: "18px" }}>Viestisi</Form.Label>
        <Form.Control
          className="bg-transparent text-foreground"
          as="textarea"
          value={message}
          rows={5}
          placeholder="..."
          required
          onChange={e => setMessage(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="contactForm.captcha" className="pb-4">
        <Form.Label style={{ fontSize: "18px" }} title="Botit ei tiedä heh heh">
          Paljonko on kaksi plus kaksi? Kirjoita numeroin
        </Form.Label>
        <Form.Control
          className="bg-transparent text-foreground"
          type="text"
          value={captcha}
          placeholder=""
          required
          onChange={e => setCaptcha(e.target.value)}
        />
      </Form.Group>

      <div className="w-100 d-flex">
        <Button
          variant="primary"
          type="submit"
          className="me-auto w-25"
          style={{ fontSize: "18px" }}
        >
          Lähetä
        </Button>

        <div className="ms-auto">
          <Form.Check
            type="checkbox"
            checked={newsletter}
            id="newsletter"
            label="Tilaa samalla uutiskirje"
            onChange={e => setNewsletter(e.target.checked)}
          />
        </div>
      </div>
    </Form>
  );
};

export default ContactForm;

import React from "react";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const ContactInfo = () => {
  const meta = useSiteMetadata();

  return (
    <>
      <h2>{meta.name}</h2>
      <p>
        {meta.address}
        <br />
        {meta.postalCode} {meta.city}
        <br />
        <br />
        <FontAwesomeIcon icon={faPhone} />
        &nbsp;&nbsp;
        <a href={`tel:${meta.phone}`} className="fg-fg">
          p. {meta.phone}
        </a>
        <br />
        <FontAwesomeIcon icon={faEnvelope} />
        &nbsp;&nbsp;
        <a href={`mailto:${meta.email}`} className="fg-fg">
          {meta.email}
        </a>
        <br />
        <br />
        Y-tunnus: {meta.vatId}
      </p>
    </>
  );
};

export default ContactInfo;

import React from "react";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

const StreetMap = () => {
  const meta = useSiteMetadata();

  return (
    <div>
      <iframe
        title="Sijainti kartalla"
        width="100%"
        height="480px"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src={meta.mapUrl}
      />
      <br />
      <small>
        <a href={meta.bigMapUrl} className="fg-fg">
          Näytä isompi kartta
        </a>
      </small>
    </div>
  );
};

export default StreetMap;

/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContactForm from "../../src/components/ContactForm";
import ContactInfo from "../../src/components/ContactInfo";
import StreetMap from "../../src/components/StreetMap";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components), {Container, Row, Col} = _components;
  if (!Col) _missingMdxReference("Col", true);
  if (!Container) _missingMdxReference("Container", true);
  if (!Row) _missingMdxReference("Row", true);
  return React.createElement(Container, null, React.createElement(_components.h1, null, "Ota yhteyttä"), React.createElement(Row, {
    className: "g-5 mt-2"
  }, React.createElement(Col, {
    sm: 12,
    lg: 6,
    className: "pe-lg-5"
  }, React.createElement(_components.p, null, "Kiinnostuitko? Voit jättää viestin oheisella lomakkeella. Me (minä) vastaamme\nsiihen heti kun ehdimme. Viestissä voit kuvailla hieman ongelmaasi, esittää\ntarkentavia kysymyksiä tai ihan vaan kertoa, että tilaat yhden tuollaisen hienon\nuutiskirjeen! Se ei maksa mitään, eikä yhteydenotto tietenkään sido sinua\nmihinkään."), React.createElement(_components.p, null, "Olipa niin tai näin, tietosi käsitellään asianmukaisesti (GDPR) ja uutiskirjeen\ntilauksen voit jopa perua koska tahansa. Sähköpostiosoitettasi ei\nluonnollisestikaan laiteta millekään roskalistalle, eikä itse uutiskirje edes\nilmesty kuin korkeintaan kerran viikossa. Uutiskirjeen sisällöstä ei voi tässä\nvaiheessa sanoa mitään muuta kuin että se liittyy jollakin lailla näihin\ntietokonehommiin."), React.createElement(_components.p, null, "Niin ja mukavaa kun löysit sivustolle, kiitos käynnistä ja tervetuloa uudelleen!")), React.createElement(Col, {
    sm: 12,
    lg: 6
  }, React.createElement(ContactForm))), React.createElement("br"), React.createElement(Row, null, React.createElement(Col, {
    sm: 12,
    lg: 6
  }, React.createElement(ContactInfo)), React.createElement(Col, {
    sm: 12,
    lg: 6
  }, React.createElement(StreetMap))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
